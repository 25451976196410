import React from "react"
import { graphql } from "gatsby"
import AboutUs from "../../components/pages/AboutUs"

const lang = "es"

const About = ({ data, pageContext }) => {
  const {
    seoDataJson,
    aboutImagesDesktop,
    aboutImagesMobile,
    profileImages,
  } = data
  const seoData = {
    url: seoDataJson.url_es,
    titleSeo: seoDataJson.title_es,
    description: seoDataJson.description_es,
    alternateLanguage: seoDataJson.alternateLanguage_es,
    alternateUrl: seoDataJson.alternateUrl_es,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "ABOUT"
  })

  return (
    <AboutUs
      lang={lang}
      aboutImagesDesktop={aboutImagesDesktop.nodes.map(
        node => node.childImageSharp
      )}
      aboutImagesMobile={aboutImagesMobile.nodes.map(
        node => node.childImageSharp
      )}
      profileImages={profileImages}
      seoData={seoData}
      urls={resultUrls}
    />
  )
}

export const pageQuery = graphql`
  query aboutEsQuery {
    aboutImagesDesktop: allFile(
      filter: {
        relativePath: { glob: "group/about_us/desktop/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }

    aboutImagesMobile: allFile(
      filter: {
        relativePath: { glob: "group/about_us/mobile/*" }
        extension: { eq: "jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seoDataJson(name: { eq: "about" }) {
      url_es
      title_es
      description_es
      alternateLanguage_es
      alternateUrl_es
    }
    profileImages: allFile(
      filter: { relativePath: { glob: "profiles/*" }, extension: { eq: "jpg" } }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
export default About
